
// 	BUTTONS

.btn01 {
  width: auto;
  height: auto;
  background: $Amarillo-BdB;
  border: 2px solid $Amarillo-BdB;
  border-radius: 100px;
  box-shadow: 0 1px 5px 0 rgba($Grey-1-BdB, 0.3);
  padding: 0px 15px;
  color: $white;
  text-align: center;
  display: inline-block;
  margin: 0 auto;
  transition: 0.3s;
  @include textProp('OpenSans-Bold', 14px, 2.75);
  text-transform: uppercase;
  min-width: 180px;
  span {
    display: inline-block;
    transition: 0.3s;
    //transition: 0.3s;
  }
    &:focus {
      border:none;
      outline: none;
    }
    &:-moz-focus-inner {
      border: 0;
      padding: 0;
  }
  &:hover {
    background: $white;
    color: $Azul-2-BdB;
    cursor: pointer;
    span {
      transform: translateX(8px);
    }
  }

  @media (min-width: $breakpoint-tablet) {
    @include textProp('OpenSans-Bold', 16px, 50px);
    min-width: 220px;
  }
}

.btn-appstore, .btn-playstore {
  display: inline-block;
  width: 120px;
  height: 36px;
  border-radius: 5px;
  border: 2px solid;
  opacity: 0.5;
  //transition: 0.3s;
  &:hover {
    opacity: 1;
  }
  @media (min-width: $breakpoint-tablet) {
    width: 140px !important;
    height: 42px !important;
  }
}

.btn-playstore {
  &--white {
    background:url(../images/btn-playstore-white.svg) center no-repeat;
    border-color: $white;
    background-size: 80%;
  }
  &--color {
    background:url(../images/btn-playstore-color.svg) center no-repeat;
    border-color: #A6A6A6;
    background-size: 100%;
    width: 107px;
    height: 32px;
    opacity: 1;
  }
}

.btn-appstore {
  &--white {
    background:url(../images/btn-appstore-white.svg) center no-repeat;
    border-color: $white;
    background-size: 80%;
  }
  &--color {
    background:url(../images/btn-appstore-color.svg) center no-repeat;
    border-color: #A6A6A6;
    background-size: 100%;
    width: 107px;
    height: 32px;
    opacity: 1;
  }
}

// .btnPlayVideo {
//   display: block;
//   width: 64px;
//   height: 64px;
//   border-radius: 50%;
//   border:2px solid $Amarillo-BdB;
//   margin: 30px auto 0;
//   position: relative;
//   &:after {
//     content: "";
//     display: block;
//     width: 22px;
//     height: 27px;
//     background: url(../images/play01.svg)center no-repeat;
//     background-size: 100%;
//     position: absolute;
//     left: 22px;
//     top: 17px;
//   }
// }


//LINKS

.link01 {
  display: block;
  width: auto;
  height: auto;
  color: $white;
  @include textProp('OpenSans-Bold', 16px, 2.75);
  text-decoration: underline;
  padding: 0 5px;
}

.link02 {
  color: $white;
  @include textProp('OpenSans-Regular', 14px, 16px);
  text-decoration: none;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
}

//ANCORS

.up-arrow {
  display: block;
  width: 48px;
  height: 48px;
  background: url(../images/arrow-up-blue.svg) center no-repeat;
  position: relative;
  margin: 0 auto;
  @media (min-width: $breakpoint-tablet) {
    margin: 0 -14px 0 auto;
  }
  @media (min-width: $breakpoint-desktop) {
    right: 0;
    margin-right: -190px;
    margin-top: -80px;
    position: absolute;
  }
}
