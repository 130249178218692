
//  ********************
//  **** BREAK POINTS  ****
//  ********************

$breakpoint-desktop: 1200px;
$breakpoint-tablet: 768px;
$breakpoint-mobile: 320px;

// ********************
// **** COLORS ****
// ********************

$white: #ffffff;
$black: #000000;

//paleta primaria
$Azul-BdB: #002C76;
$Amarillo-BdB: #FDC130;
$Rojo-BdB: #D0021B;
$Verde-BdB: #7AD468;

// paleta secudaria
$Azul-2-BdB: #0040A8;
$Azul-3-BdB: #4070BE;
$Azul-4-BdB: #0085E0;
$Azul-5-BdB: #C4D4E3;
$Azul-6-Bdb: #EBF0F5;
$Amarillo-2-BdB: #EDA705;
$Rojo-2-BdB: #E33031;
$Grey-1-BdB: #4A4A4A;
$Grey-2-BdB: #9B9B9B;
$Grey-3-BdB: #BEC1C6;
$Grey-4-BdB: #E6E6E6;
$Grey-5-BdB: #EFEFEF;
$Grey-6-Bdb: #CCCCCC;
$Grey-7-Bdb: #333333;
$Grey-8-Bdb: #999999;
$Grey-10-Bdb: #666666;
$Grey-11-Bdb: #F2F2F2;
$Grey-bg: #F9F9F9;
$BG-BdB: #F6F8FA;
$bg-download: #DCE5EE;
