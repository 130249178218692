
// GENERAL

.bg-blueClear {
  background: $BG-BdB;
}

.mb-only {
  display: block
}
.tb-only, .dk-only {
  display: none !important;
}
.ovHidden {
  overflow: hidden;
}


@media (min-width: $breakpoint-tablet) {
  .max1200 {
    max-width: 1200px;
    display: block;
    margin: 0 auto;
    position: relative;
  }
  .mb-only {
    display: none !important;
  }
  .tb-only, .dk-only {
    display: block !important;
  }
}
