
// FOOTER

.footer {
  display: block;
  width: 100%;
  height: auto;
  background: $Azul-2-BdB;
  .tit03 {
    margin-bottom: 20px;
  }
  .cont-footer, .legal {
    display: block;
    width: 100%;
    padding: 40px 20px;
  }
  .cont-footer {
    ul {
      li {
        margin-bottom: 16px;
      }
    }
    .otros {
      margin-bottom: 50px;
    }
    .descargas {
      text-align: left;
      .tit03 {
        text-align: left;
      }
      .btn-playstore {
        margin-right: 20px;
      }
    }
  }
  .legal {
    text-align: center;
    background: $Azul-BdB;
    .aval-fogafin {
      display: block;
      max-width: 110px;
      margin: 0 auto 20px;
    }
  }
  .text-address {
    @include textProp('OpenSans-Regular', 10px, 14px);
    color: $Azul-5-BdB;
    margin-top: 16px;
    text-align: center;
    strong {
      font-weight: bold;
    }
  }
  .logo-footer {
    display: block;
    margin: 0 0 20px 0;
  }
  .mb-only {
    display: block;
  }
  .tb-only {
    display: none;
  }

  //TABLET
  @media (min-width: $breakpoint-tablet) {
    .cont-footer, .legal {
      padding: 40px;
    }

    .cont-footer {
      position: relative;
      padding: 40px;
      .logo-footer {
        position: absolute;
        right: 50px;
        bottom: 30px;
      }

      .otros, .descargas {
        display: inline-block;
        width: 30%;
        //max-width: 180px;
        vertical-align: top;
      }

      .descargas {
        max-width: 206px;
      }

      .otros{
        margin-bottom: 0;
        ul {
          li {
            margin-bottom: 20px;
          }
        }
      }

      .descargas {
        .btn-playstore {
          margin-right: 0;
          margin-bottom: 20px;
        }
      }
    }

    .legal {
      .aval-fogafin, >div {
        display: block;
        vertical-align: middle;
      }
      .txt-legal {
        width: 60%;
        text-align: left;
      }
      .aval-fogafin {
        width: 8%;
        position: absolute;
        right: 40px;
      }
    }
    .text-address {
      text-align: left;
    }
  }

  //DESKTOP
  @media (min-width: $breakpoint-desktop) {
    .cont-footer, .legal {
      padding: 40px;
    }
    .cont-footer {
      max-width: 1200px;
      padding: 40px 0px;
      margin: 0 auto;
      .logo-footer {
        right: 0px;
      }
      ul {
        li {
          display: inline-block;
          margin-right: 30px;
        }
      }
      .otros {
        display: block;
        width: auto;
        margin-bottom: 20px;
      }
      .descargas {
        max-width: 100%;
        display: block;
        width: auto;
        .btn-playstore {
          margin-bottom: 0;
          margin-right: 20px;
        }
      }
    }
    .legal {
      .aval-fogafin {
        right: 0;
      }
      >div {
        max-width: 1200px;
        margin: 0 auto;
        position: relative;
      }
    }
  }
}
