

// partials base
@import "base/reset";
@import "base/variables";
@import "base/fonts";
@import "base/mixins";
@import "base/text";
@import "base/buttons";
//@import "base/animations";

// partials components
//@import "components/header";
@import "components/footer";
// @import "components/faq";
// @import "components/download";
// @import "components/video";
// @import "components/options";
// @import "components/benefits";
// @import "components/opening";

// GENERAL
@import "components/general";
//@import "especificos/general-servicios";
