
// TITLES

.tit01{
  @include textProp('DTLArgoT', 26px, 30px);
  font-weight: normal;
  strong {
    font-weight: bold;
  }
  &--white {
    color: $white;
  }

  &--blue {
    color: $Azul-2-BdB;
  }

  @media (min-width: $breakpoint-tablet) {
    @include textProp('DTLArgoT', 35px, 40px);
  }

  @media (min-width: $breakpoint-desktop) {
    @include textProp('DTLArgoT', 40px, 46px);
  }
}

.tit02{
  @include textProp('DTLArgoT', 20px, 25px);
  text-align: center;
  font-weight: normal;
  strong {
    font-weight: bold;
  }
  &--blue {
    color: $Azul-2-BdB;
  }

  @media (min-width: $breakpoint-tablet) {
    @include textProp('DTLArgoT', 24px, 28px);
  }
  @media (min-width: $breakpoint-desktop) {
    @include textProp('DTLArgoT', 26px, 30px);
  }
}

.tit03 {
  @include textProp('OpenSans-Regular', 14px, 19px);
  text-transform: uppercase;
  strong {
    font-weight: bold;
  }
  &--blue {
    color: $Azul-2-BdB;
  }
  &--grey {
    color: $Azul-BdB;
  }
  &--white {
    color: $white;
  }
}

.tit04{
  @include textProp('OpenSans-Bold', 12px, 16px);
  font-weight: bold;
  text-transform: none;

  &--blue {
    color: $Azul-2-BdB;
  }
  &--grey {
    color: $Azul-BdB;
  }
  &--white {
    color: $white;
  }
}

.tit05 {
  @include textProp('DTLArgoT', 24px, 30px);
  font-weight: normal;
  strong {
    font-weight: bold;
  }

  &--white {
    color: $white;
    a {
      color: $white;
    }
  }

  &--blue {
    color: $Azul-2-BdB;
    a{
     color: $Azul-2-BdB;
   }
  }
  @media (min-width: $breakpoint-tablet) {
    @include textProp('DTLArgoT', 30px, 38px);
  }

}
// TEXT

.txt01{
  @include textProp('OpenSans-Regular', 16px, 24px);
  font-weight: normal;
  strong {
    font-weight: bold;
  }
  &--white {
    color: $white;
  }
  &--grey {
    color: $Grey-1-BdB;
  }
  .italic {
    font-size: 'OpenSans-Regular';
  }

  .bullet {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 10px;
    border-radius: 50%;
    background: $Grey-1-BdB;
  }

  @media (min-width: $breakpoint-desktop) {
    @include textProp('OpenSans-Regular', 16px, 24px);
  }
}


.txt02{
  @include textProp('OpenSans-Regular', 14px, 22px);
  strong {
    //font-weight: bold;
    font-weight: bold;
  }

  &--blue, a {
    color: $Azul-BdB;
  }
  &--grey {
    color: $Grey-7-Bdb;
  }
  @media (min-width: $breakpoint-tablet) {
    @include textProp('OpenSans-Regular', 16px, 24px);
  }
}

.txt03{
  @include textProp('OpenSans-Regular', 12px, 18px);
  strong {
    font-weight: bold;
  }
  &--white {
    color: $white;
  }
  &--grey {
    color: $Grey-1-BdB;
  }
  &--grey02 {
    color: $Grey-8-Bdb;
  }
  &--clear {
    color: $Azul-5-BdB;
  }
  @media (min-width: $breakpoint-tablet) {
    @include textProp('OpenSans-Regular', 14px, 18px);
  }
}

.txt04{
  @include textProp('OpenSans-Regular', 14px, 20px);
  strong {
    font-weight: bold;
  }
  &--white {
    color: $white;
  }
  &--grey {
    color: $Grey-1-BdB;
  }
  &--clear {
    color: $Azul-5-BdB;
  }
  @media (min-width: $breakpoint-tablet) {
    @include textProp('OpenSans-Regular', 16px, 26px);
  }
}

//Numbers
.number01 {
  display: block;
  position: absolute;
  width: 48px;
  height: 48px;
  background: $Azul-6-Bdb;
  text-align: center;
  border-radius: 50%;
  left: 0;
  top: 3px;
  @include textProp('DTLArgoT', 30px, 48px);
    @media (min-width: $breakpoint-tablet) {
      width: 64px;
      height: 64px;
      @include textProp('DTLArgoT', 40px, 64px);
    }
}

//destacados
.dest01 {
  display: block;
  width: auto;
  height: auto;
  padding: 16px;
  background:$Grey-11-Bdb;
  border-radius: 8px;
  color: $Grey-10-Bdb;
  strong {
    font-family:'OpenSans-Bold';
    color: $Azul-2-BdB;
  }
}
