
// FONTS

@font-face {
    font-family:'DTLArgoT';
    src: url("../fonts/open-sans/DTLArgoT.ttf");
}

@font-face {
    font-family:'OpenSans-Regular';
    src: url("../fonts/open-sans/OpenSans-Regular.ttf");
}

@font-face {
    font-family:'OpenSans-SemiBold';
    src: url("../fonts/open-sans/OpenSans-SemiBold.ttf");
}

@font-face {
    font-family:'OpenSans-Bold';
    src: url("../fonts/open-sans/OpenSans-Bold.ttf");
}

@font-face {
    font-family:'OpenSans-ExtraBold';
    src: url("../fonts/open-sans/OpenSans-ExtraBold.ttf");
}

@font-face {
    font-family:'OpenSans-Light';
    src: url("../fonts/open-sans/OpenSans-Light.ttf");
}


// fix rendering all fonts
* {
box-sizing: border-box;
-moz-box-sizing: border-box;
-webkit-box-sizing: border-box;

-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale
}
