
// MIXINS

@mixin textProp($property01, $property02, $property03) {
  font-family: $property01;
  font-size: $property02;
  line-height: $property03;
}

@mixin varticalGradient($color01, $color02) {
  background-image: linear-gradient(-180deg, $color01 0%, $color02 96%);
}
